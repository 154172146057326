<template>
  <div>
    <div class="px-2">

      <div class="row">
        <div class="w-100 mb-1">
          <div class="row">

            <div class="col-md-4">
              <h6> State</h6>

            </div>
            <div class="col-md-4">
              <h6> State Price Rate</h6>
            </div>
          </div>
          <div v-for="(serviceStatePriceRate,index) in settingServiceStatePriceRate?.value ?? []" :key="index">

            <div class="row mb-1">

              <div class="col-md-4">

                <input v-model="serviceStatePriceRate.state" type="text" readonly class="form-control" id="serviceState" placeholder="Service State">

              </div>
              <div class="col-md-4">

                <input v-model="serviceStatePriceRate.rate" type="text" class="form-control" id="serviceStatePriceRate" placeholder="Service State Price Rate">

              </div>
            </div>


          </div>

          <div class="row">
            <div class="col-md-8 d-flex justify-content-end">
              <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>

            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingServiceStatePriceRate",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['serviceStatePriceRate'],
  data() {
    return {
      currentSetting: this.serviceStatePriceRate,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    },
    ...mapGetters({
      settingServiceStatePriceRate: 'appSettings/settingServiceStatePriceRate',
    }),
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Service state price rate updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>